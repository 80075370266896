import Bowser from 'bowser'

function createParser() {
  return Bowser.getParser(window.navigator.userAgent)
}

export function isIos() {
  return createParser().getOSName() === Bowser.OS_MAP.iOS
}

export function isApplePaySupported() {
  // eslint-disable-next-line
  return isIos() && window?.ApplePaySession && window?.ApplePaySession.canMakePayments()
}

export function isGooglePaySupported() {
  return !isDesktop() && isAndroid()
}

export function isAndroid() {
  return createParser().getOSName() === Bowser.OS_MAP.Android
}

export function isStandAlone() {
  return window.matchMedia('(display-mode: standalone)').matches || document.referrer.includes('android-app://')
}

export function isDesktop() {
  return createParser().getPlatformType() === Bowser.PLATFORMS_MAP.desktop
}

export function isMaintance() {
  const today = new Date()
  const from = new Date(2023, 8, 28, 0, 0, 0, 0)
  const to = new Date(2023, 9, 2, 12, 0, 0, 0)

  return today >= from && today < to
}
